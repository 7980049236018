import Intro from "./Intro";
import HasHealthcard from "./HasHealthcard";
import Healthcard from "./Healthcard";
import NoHealthcard from "./NoHealthcard"
import HealthcardLookupLoading from "./HealthcardLookupLoading";
import NoHealthcardLookupLoading from "./NoHealthcardLookupLoading";
import Information from "./Information";
import InformationLoading from "./InformationLoading";
import CheckInSuccess from "./CheckInSuccess";
import Error from "./Error";
import { useState } from "react";

function MultistepForm() {

  const [ step, setStep ] = useState("intro");

  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ dob, setDob ] = useState("");
  const [ sex, setSex ] = useState("");
  const [ hc, setHc ] = useState("");
  const [ versionCode, setVersionCode ] = useState("");
  const [ street, setStreet ] = useState("");
  const [ city, setCity ] = useState("");
  const [ province, setProvince ] = useState("ON");
  const [ phone, setPhone ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ waitTime, setWaitTime ] = useState("");

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setDob("");
    setSex("");
    setHc("");
    setVersionCode("");
    setStreet("");
    setCity("");
    setProvince("ON");
    setPhone("");
    setEmail("");
    setWaitTime("")
  }

  const props = { 
    data: { step, firstName, lastName, dob, sex, hc, versionCode, street, city, province, phone, email, waitTime }, 
    setters: { setStep, setFirstName, setLastName, setDob, setSex, setHc, setVersionCode, setStreet, setCity, setProvince, setPhone, setEmail, setWaitTime },
    reset: resetState
  };

  switch (step) {
    case "intro":
      return <Intro {...props} />;
    case "has_healthcard":
      return <HasHealthcard {...props} />;
    case "healthcard":
      return <Healthcard {...props} />;
    case "no_healthcard":
      return <NoHealthcard {...props} />;
    case "healthcard_lookup_loading":
      return <HealthcardLookupLoading {...props} />
    case "information": 
      return <Information {...props} />
    case "information_loading": 
      return <InformationLoading {...props} />
    case "no_healthcard_lookup_loading":
      return <NoHealthcardLookupLoading {...props} />
    case "pt_found_appt_Create":
      return <Intro {...props} />;
    case "check_in_success":
      return <CheckInSuccess {...props} />;
    case "error":
      return <Error {...props} />;
    default:
      return null;
  }
}

export default MultistepForm;
