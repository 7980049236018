import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useState } from "react";
import classNames from "../Util/classNames";
import format from "../Util/masker";
import validateEmail from "../Util/emailValidator";

function Information(props) {
    const [firstName, setFirstName] = useState(props.data.firstName);
    const [lastName, setLastName] = useState(props.data.lastName);
    const [sex, setSex] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dob, setDob] = useState(props.data.dob);
    const [street, setStreet] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("ON");

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [sexError, setSexError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [streetError, setStreetError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [provinceError, setProvinceError] = useState(false);

    const handleTextFieldChangeHandler = (value, setState, setError) => {
        setError(false);
        setState(value);
    };

    const handleAlphaNumericChangeHandler = (value, setState, setError) => {
        setError(false);
        setState(value);
    };

    const dobChangeHandler = (value) => {
        setDobError(false);
        const strippedValue = value.replaceAll("/", "");
        if (value.length <= 10 && !isNaN(+strippedValue)) {
            setDob(format("XXXX/XX/XX", strippedValue));
        }
    };

    const phoneChangeHandler = (value) => {
        setPhoneError(false);
        let strippedValue = value.replaceAll(/[() -]/g, "");
        if (strippedValue.length <= 10 && !isNaN(+strippedValue)) {
            setPhone(format("(XXX) XXX-XXXX", strippedValue));
        }
    };

    const sexChangeHandler = (value) => {
        setSexError(false);
        setSex(value);
    };

    const provinceChangeHandler = (value) => {
        setProvinceError(false);
        setProvince(value);
    };

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                    Please enter your information below
                </span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                All fields are required. Your information will remain private and confidential. 
            </p>

            <div className="md:grid md:grid-cols-3 md:gap-6 mt-6">
                <div className="mt-5 md:mt-0 md:col-span-3 lg:col-span-2">
                    <form action="#" method="POST">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="firstName"
                                        className="block text-sm font-medium text-white"
                                    >
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        placeholder="Tom"
                                        value={firstName}
                                        onChange={(e) => {
                                            handleTextFieldChangeHandler(
                                                e.target.value,
                                                setFirstName,
                                                setFirstNameError
                                            );
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !firstNameError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !firstNameError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid first name
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="lastName"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        id="lastName"
                                        placeholder="Smith"
                                        value={lastName}
                                        onChange={(e) => {
                                            handleTextFieldChangeHandler(
                                                e.target.value,
                                                setLastName,
                                                setLastNameError
                                            );
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !lastNameError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !lastNameError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid last name
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="Sex"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Sex
                                    </label>
                                    <select
                                        id="sex"
                                        name="sex"
                                        onChange={(e) => {
                                            sexChangeHandler(e.target.value);
                                        }}
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option className="hidden"></option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                        <option value="O">Other</option>
                                    </select>
                                    <div
                                        className={classNames(
                                            !sexError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !sexError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    A sex must be selected  
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="tomsmith@abc.com"
                                        value={email}
                                        onChange={(e) => {
                                            handleAlphaNumericChangeHandler(
                                                e.target.value,
                                                setEmail,
                                                setEmailError
                                            );
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !emailError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !emailError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid email
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Phone Number
                                    </label>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="(519) 123-4567"
                                        value={phone}
                                        onChange={(e) => {
                                            phoneChangeHandler(e.target.value);
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !phoneError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !phoneError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid phone number
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-2">
                                <div className="relative">
                                    <label
                                        htmlFor="dob"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Date of Birth
                                    </label>
                                    <input
                                        type="text"
                                        name="dob"
                                        id="dob"
                                        placeholder="1995/04/28"
                                        value={dob}
                                        onChange={(e) => {
                                            dobChangeHandler(e.target.value);
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !dobError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !dobError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid date of birth
                                </p>
                            </div>

                            <div className="col-span-6">
                                <div className="relative">
                                    <label
                                        htmlFor="street"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Street address
                                    </label>
                                    <input
                                        type="text"
                                        name="street"
                                        id="street"
                                        placeholder="Unit 122-Eagle St N"
                                        value={street}
                                        onChange={(e) => {
                                            handleAlphaNumericChangeHandler(
                                                e.target.value,
                                                setStreet,
                                                setStreetError
                                            );
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !streetError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !streetError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid street address
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <div className="relative">
                                    <label
                                        htmlFor="city"
                                        className="block text-sm font-medium text-white"
                                    >
                                        City
                                    </label>
                                    <input
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder="Waterloo"
                                        value={city}
                                        onChange={(e) => {
                                            handleTextFieldChangeHandler(
                                                e.target.value,
                                                setCity,
                                                setCityError
                                            );
                                        }}
                                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div
                                        className={classNames(
                                            !cityError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !cityError && "invisible",
                                        "mt-1 text-sm text-red-500"
                                    )}
                                >
                                    Enter a valid city
                                </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <div className="relative">
                                    <label
                                        htmlFor="province"
                                        className="block text-sm font-medium text-white"
                                    >
                                        Province
                                    </label>
                                    <select
                                        id="province"
                                        name="province"
                                        autoComplete="province"
                                        value={0}
                                        onChange={(e) => {
                                            provinceChangeHandler(e.target.value);
                                        }}
                                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="ON">Ontario</option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">British Columbia</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                        <option value="NS">Nova Scotia</option>
                                        <option value="PE">Prince Edward Island</option>
                                        <option value="QC">Quebec</option>
                                        <option value="SK">Saskatchewan</option>
                                        <option value="NT">Northwest Territories</option>
                                        <option value="NU">Nunuvut</option>
                                        <option value="YT">Yukon</option>
                                    </select>
                                    <div
                                        className={classNames(
                                            !provinceError && "invisible",
                                            "absolute -inset-y-6 pt-6 right-0 pr-3 flex items-center pointer-events-none"
                                        )}
                                    >
                                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                                    </div>
                                </div>
                                <p
                                    className={classNames(
                                        !provinceError && "invisible",
                                        "mt-2 text-sm text-red-500"
                                    )}
                                >
                                    A valid province must be selected.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <button
                onClick={() => {
                    let isValid = true;

                    if (!(firstName.length > 0)) {
                        isValid = false;
                        setFirstNameError(true);
                    }

                    if (!(lastName.length > 0)) {
                        isValid = false;
                        setLastNameError(true);
                    }

                    if (!(sex === "M" || sex === "F") || sex === "O") {
                        isValid = false;
                        setSexError(true);
                    }

                    if (!validateEmail(email.trim())) {
                        isValid = false;
                        setEmailError(true);
                    }

                    const phoneStripped = phone.replaceAll(/[() -]/g, "");
                    if (!(phoneStripped.length === 10 && !isNaN(+phoneStripped))) {
                        isValid = false;
                        setPhoneError(true);
                    }

                    if (dob.length === 10) {
                        let dates = dob.split("/");

                        const year = parseInt(dates[0]);
                        const month = parseInt(dates[1]);
                        const day = parseInt(dates[2]);

                        if (!(
                                year > 1900 &&
                                year < 2030 &&
                                month > 0 &&
                                month <= 12 &&
                                day > 0 &&
                                day <= 31
                            )) {
                            isValid = false;
                            setDobError(true);
                        }
                    } else {
                        isValid = false;
                        setDobError(true);
                    }

                    if (!(street.length > 0)) {
                        isValid = false;
                        setStreetError(true);
                    }

                    if (!(city.length > 0)) {
                        isValid = false;
                        setCityError(true);
                    }

                    if (!(province === "ON" || province === "AB" || province === "BC" || province === "MB" || province === "NB" || province === "NL" || province === "NS" || province === "PE" || province === "QC" || province === "SK" || province === "NT" || province === "NU" || province === "YT")) {
                        isValid = false;
                        setProvinceError(true);
                    }

                    if (isValid) {
                        props.setters.setFirstName(firstName.trim());
                        props.setters.setLastName(lastName.trim());
                        props.setters.setDob(dob);
                        props.setters.setSex(sex);
                        props.setters.setStreet(street.trim());
                        props.setters.setCity(city.trim());
                        props.setters.setProvince(province);
                        props.setters.setPhone(phone);
                        props.setters.setEmail(email.trim());
 
                        props.setters.setStep("information_loading");
                    }
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Submit
            </button>
        </>
    );
}

export default Information;
