
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useState } from 'react';
import classNames from '../Util/classNames';
import format from "../Util/masker";

function NoHealthcard(props) {

    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [dobError, setDobError] = useState(false);

    const lastNameChangeHandler = (value) => {
        setLastNameError(false)
        if (!/[^a-zA-Z]/.test(value)) {
            setLastName(value);
        }
    }

    const dobChangeHandler = (value) => {
        setDobError(false);
        const strippedValue = value.replaceAll("/", "");
        if (value.length <= 10 && !isNaN(+strippedValue)) {
            setDob(format("XXXX/XX/XX", strippedValue));
        }
    }

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Enter your last name and date of birth</span>
            </h2>

            <div className="grid grid-cols-2 max-w-lg mt-6">
                <div className="mr-4 col-span-1">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-white"
                    >
                        Last name
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={lastName}
                            onChange={(e) => { lastNameChangeHandler(e.target.value) }}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Smith"
                        />

                        <div className={classNames(!lastNameError && "invisible", "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none")}>
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                    </div>
                    <p className={classNames(!lastNameError && "invisible", "mt-2 text-sm text-red-500")}>
                        A last name must be entered.
                    </p>
                </div>
                <div className="">
                    <label
                        htmlFor="dob"
                        className="block text-sm font-medium text-white"
                    >
                        Date of birth (Y/M/D)
                    </label>
                    <div className="mt-1 relative">
                        <input
                            type="text"
                            name="dob"
                            id="dob"
                            value={dob}
                            onChange={(e) => { dobChangeHandler(e.target.value) }}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="1999/01/20"
                        />

                        <div className={classNames(!dobError && "invisible", "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none")}>
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                    </div>
                    <p className={classNames(!dobError && "invisible", "mt-2 text-sm text-red-500")}>
                        A valid date of birth must be entered.
                    </p>
                </div>
            </div>

            <button
                onClick={() => {

                    let isValid = true;

                    if (!(lastName.length > 0)) {
                        isValid = false;
                        setLastNameError(true);
                    }

                    if (dob.length === 10) {
                        let dates = dob.split("/");

                        const year = parseInt(dates[0]);
                        const month = parseInt(dates[1]);
                        const day = parseInt(dates[2]);
                        
                        if (!(year > 1900 && year < 2030 && month > 0 && month <= 12 && day > 0 && day <= 31)) {
                            isValid = false;
                            setDobError(true);
                        }
                    } else {
                        isValid = false;
                        setDobError(true);
                    }
                    
                    if (isValid) {
                        props.setters.setLastName(lastName);
                        props.setters.setDob(dob);
                        props.setters.setStep("no_healthcard_lookup_loading")
                    }
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Next
            </button>
        </>
    );
}

export default NoHealthcard;
