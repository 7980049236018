function Intro(props) {
    return (
        <>
            <h2 className="text-4xl font-extrabold text-white sm:text-4xl">
                <span className="block">Walk In Clinic</span>
            </h2>
            <p className="mt-8 text-lg leading-6 text-white">
                Please check in to the clinic. All your information is
                confidential.
            </p>
            <p className="mt-16 text-lg leading-6 text-white">
                Please have a seat while you fill the registration form. An estimated wait time will be shown once registration is complete.
            </p>
            <button
                onClick={() => {
                    props.setters.setStep("has_healthcard")
                }}
                className="mt-28 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Get started
            </button>

            <p className="mt-4 text-sm leading-6 text-gray-400">
                By clicking the button above, you agree to the terms and conditions of this clinic which can be found at cloudmedical.ca. This is a telemedicine clinic with a medical graduate under the direct supervision of an Ontario physician. 
            </p>
            <p className="mt-1 text-sm leading-6 text-gray-400">
                
            </p>
        </>
    );
}

export default Intro;
