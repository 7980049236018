import cloudlogo from "./images/cloud-long.svg";
import emeraldPharmacyLogo from "./images/emeraldpharmacy.png";
import MultistepForm from "./Components/MultistepForm";

function App() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div className="bg-gray-800 rounded-lg shadow-xl overflow-hidden">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                        <div className="lg:self-center">
                            <a href="/">
                                <img className="h-10 mb-10" src={cloudlogo}/>
                            </a>
                            <MultistepForm />
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex center">
                    <p className="mr-4">Service provided by</p>
                    <a href="https://www.emeraldrx.ca/" target={"_blank"}>
                        <img className="h-20" src={emeraldPharmacyLogo} />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
