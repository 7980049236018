import { useEffect } from "react";
import Spinner from "../images/spinner.svg"

function NoHealthcardLookupLoading(props) {

    useEffect(() => {

        fetch("https://oscar.cloudmedical.ca:3000/searchPatientByLastnameAndDob", {
            method: "POST",
            body: new URLSearchParams({
                "lastname": props.data.lastName.toUpperCase(),
                "dob": props.data.dob.replaceAll("/", "-"),
            })
        })
        .then(res => res.json())
        .then((data) => {
            console.log("data: " + data.status);
            if (data.status === "success") {
                props.setters.setWaitTime(data.enterTime)
                props.setters.setStep("check_in_success");
            } else if (data.status === "does_not_exist") {  
                props.setters.setStep("information");
            } else {
                props.setters.setStep("error");
            }
        })
        .catch((err) => {
            console.log("Error " + err);
            props.setters.setStep("error");
        })

    }, []);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                We are looking up your information. 
            </p>
            <img
                className="animate-spin mt-8 h-10 w-10 text-blue-500"
                src={Spinner}>
            </img>
        </>
    );
}

export default NoHealthcardLookupLoading;
