
const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;
  
    return `${hours}:${minutes} ${period}`;
}

function CheckInSuccess(props) {

    const enterTime = convertFrom24To12Format(props.data.waitTime);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">You are checked in!</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                We ask that only the patient and 1 caregiver stay in the waiting area. All others should wait in their vehicle.
            </p>
            <p className="mt-2 text-lg leading-6 text-white">
                Please have a seat and you will be called in at approximately {enterTime}. Please note that this is only an estimate. 
            </p>
            <button
                onClick={() => {
                    props.reset();
                    props.setters.setStep("intro");
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Register another patient
            </button>
        </>
    );
}

export default CheckInSuccess;
