import { useEffect } from 'react';
import Spinner from "../images/spinner.svg"
import format from "../Util/masker";


function InformationLoading(props) {

    useEffect(() => {        
         
        // Change phone number to XXX-XXX-XXXX format
        const strippedPhone = props.data.phone.replaceAll(/[() -]/g, "");
        const formattedPhone = format("XXX-XXX-XXXX", strippedPhone);

        fetch("https://oscar.cloudmedical.ca:3000/createPatient", {
            method: "POST",
            body: new URLSearchParams({
                "firstname": props.data.firstName.toUpperCase().trim(),
                "lastname": props.data.lastName.toUpperCase().trim(),
                "phone": formattedPhone,
                "street": props.data.street.toUpperCase().trim(),
                "city": props.data.city.toUpperCase().trim(),
                "province": props.data.province.toUpperCase().trim(),
                "email": props.data.email.trim(),
                "hc": props.data.hc.replaceAll("-", ""),
                "version": props.data.versionCode.toUpperCase().trim(),
                "dob": props.data.dob.replaceAll("/", "-"),
                "sex": props.data.sex.toUpperCase().trim()
            })
        })
        .then(res => res.json())
        .then((data) => {
            if (data.status === "success") {
                props.setters.setWaitTime(data.enterTime)
                props.setters.setStep("check_in_success");
            } else {
                props.setters.setStep("error");
            }
        })
        .catch((err) => {
            console.log("Error " + err);
            props.setters.setStep("error");
        }) 

    }, []); // <-- Have to pass in [] here!

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Loading</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                We are creating your profile. 
            </p>
            <img
                className="animate-spin mt-8 h-10 w-10 text-blue-500"
                src={Spinner}>
            </img>
        </>
    );
}

export default InformationLoading;
