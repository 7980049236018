import { useState } from "react";
import { RadioGroup } from "@headlessui/react";

const selections = [
    {
        name: "Yes, I do",
    },
    {
        name: "No, I don't",
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function HasHealthcard(props) {
    const [selected, setSelected] = useState(selections[0]);

    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                    Do you have an Ontario Healthcard?
                </span>
            </h2>
            <p className="my-4 text-lg leading-6 text-white">
                If your card is expired, you can still use it at this time.
            </p>
            <RadioGroup className="md:max-w-md" value={selected} onChange={setSelected}>
                <div className="bg-white rounded-md">
                    {selections.map((selection, selectionIdx) => (
                        <RadioGroup.Option
                            key={selection.name}
                            value={selection}
                            className={({ checked }) =>
                                classNames(
                                    selectionIdx === 0
                                        ? "rounded-tl-md rounded-tr-md"
                                        : "",
                                    selectionIdx === selections.length - 1
                                        ? "rounded-bl-md rounded-br-md"
                                        : "",
                                    checked
                                        ? "bg-blue-50 border-blue-200 z-10"
                                        : "border-gray-200",
                                    "relative border p-4 flex cursor-pointer focus:outline-none"
                                )
                            }
                        >
                            {({ checked }) => (
                                <>
                                    <span
                                        className={classNames(
                                            checked
                                                ? "bg-blue-500 border-transparent"
                                                : "bg-white border-gray-300",
                                            "h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
                                        )}
                                    >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <div className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                            as="span"
                                            className={classNames(
                                                checked
                                                    ? "text-blue-900"
                                                    : "text-gray-900",
                                                "block text-sm font-medium"
                                            )}
                                        >
                                            {selection.name}
                                        </RadioGroup.Label>
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            <button
                onClick={() => {
                    if (selected.name === "Yes, I do") {
                        props.setters.setStep("healthcard");
                    } else {
                        props.setters.setStep("no_healthcard")
                    }
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Next
            </button>
        </>
    );
}

export default HasHealthcard;
