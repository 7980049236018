function Error(props) {
    return (
        <>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Oops, we hit a snag</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-white">
                Please talk to staff or try again.
            </p>
            <button
                onClick={() => {
                    props.setters.setStep("intro")
                }}
                className="mt-8 bg-white white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-800 hover:bg-gray-200"
            >
                Try again
            </button>
        </>
    );
}

export default Error;
